@media (max-width:850px) {
    body {
        padding-top: 22vw;
    }

    h1 {
        font-size: 8vw !important;
    }

    h1.page-title {
        font-size: 8vw !important;
    }

    h2 {
        font-size: 4.7vw;
    }

    h3 {
        font-size: 3.8vw;
    }

    .sidebar {display:none}

    p,
    ul,
    .attorneyProfileContactInformation {
        font-size: 3.7vw;
        line-height: 5vw;
    }

    .attorneyProfilePhoto {
        height: 40vw;
    }

    section#logo img {
        width: 56vw;
        bottom: 2vw;
    }

    .slider-bg {
        height: 125vw;
    }

    .section-one {
        height: 83vw;
        margin-top: -13vw;
        padding-top: 33vw;
        background-size: 190%;
        animation-duration: 5s;
    }

    .testimonials a {top:unset;bottom:1vw;position: absolute;width:80%;left:8vw;}

    .testimonials > div {height:99vw;position: relative;}
    #slider{padding-top:12vw}

    @keyframes bg-zoom-top {
        0% {
            background-size: 175%;
        }

        100% {
            background-size: 190%;
        }
    }

    .titlebar{height:55vw;background-size:cover;background-position-y:0;background-position-x:0}
    .social-shareon-text{font-size:3.7vw}

    .blog article.post{border-bottom:2px solid #ececec;padding-bottom:9vw;margin-top:8vw}
    .red-button{padding:1.5vw 4vw;width:32%;font-size:4.5vw}
    .blog .column-main {padding-bottom:4vw;}

    .sidebar {
        display: none;
    }

    .sidebar-nav {
        padding: 0;
        display: block;
        width: 85%;
        margin: 0 auto;
    }

    .sidebar-nav .title {
        font-size: 5vw;
        border-top: 1px solid white;
        padding-top: 4vw;
        margin-top: 6vw;
        padding-bottom: 3vw;
    }

    .sidebar-nav a {
        font-size: 3.7vw;
        line-height: 5vw;
        color: white;
        text-decoration: none;
        margin-bottom:1.3vw;
        display: block;
    }

    .sidebar-nav .button {
        position: relative;
        text-align: center;
        width: 100vw;
        padding: 3.0vw 0;
        background-color: #ad2136;
        border-top: 1px solid white;
        right: 5vw;
    }

    .sidebar-nav .dropdown {
        padding-left:3vw;
    }

    .column-main {
        display: block;
        width: 85%;
        margin: 0 auto;
    }

    .featured-posts-container {
        max-width: 85vw;
    }

    .featured-posts-container .featured .post img {
        width: 85vw;
        margin-top: 5vw;
    }

    .featured-posts-container .post {
        display: block;
        max-width: 85vw;
    }

    .featured-posts-container .post img {
        width: 85vw;
    }

    .featured-posts-container .post {
        margin-top: 5vw;
    }

    .featured-posts-container article.post {
        margin-top: 0vw;
    }

    .social-share-svg {
        height: 4vw;
        padding-right: 3vw;
    }



    .section-one .title {
        font-size: 8vw;
    }

    .section-one .subtitle {
        font-size: 4.7vw;
        padding-bottom: 6vw;
    }

    .section-one .br {
        display: none;
    }

    .section-one img {
        width: 80%;
        position: relative;
        bottom: 1vw;
    }

    .section-two a {
        text-decoration: none;
    }

    .section-two>img,
    .section-three img,
    .section-four>img {
        width: 36vw;
    }

    .section-two>.title,
    .section-three .title,
    .section-four>.title {
        font-size: 8vw;
    }

    .section-two .subtitle {
        font-size: 4.7vw;
    }

    .practice-area {
        display: block;
        width: 87%;
        margin-bottom: 9vw;
    }

    .practice-area img {
        height: 56vw;
    }

    .practice-area .title {
        font-size: 4.7vw;
        padding-left: 6.2vw;
    }

    .practice-area p {
        font-size: 3.7vw;
        width: 86%;
        padding-top: 4.8vw;
    }

    .section-three div.column {
        display: block;
        padding-left: 6vw;
        width: 87%;
    }

    .section-three h1 {
        font-size: 3.7vw;
    }

    .section-three h1 strong {
        font-size: 3.4vw;
    }

    .banner .title {
        margin-top: -17vw;
    }

    .banner img {
        width: 161%;
        height: 79vw;
        position: relative;
        right: 27vw;
    }

    .banner p {
        margin-top: -2vw;
        font-size: 10vw;
        line-height: 12vw;
    }

    .banner a {
        display: block;
        text-align: center;
        margin: 2vw auto 0;
    }

    .section-four .column,
    .section-four .column.one {
        display: block;
        width: 88vw;
        padding-left: 6vw;
    }

    .section-four .column.one a img {
        padding: 4vw 6.1vw;
        height: 8vw;
    }

    .section-four .column.two .title {
        display: none;
    }

    .section-four .column.three,
    .section-four .column.four {
        display: none;
    }

    .banner-two {
        height: 44vw;
        margin-top: 14vw;
    }

    .banner-two div {
        font-size: 3.7vw;
        padding: 5vw 6vw 0vw 6vw;
        line-height: 5vw;
    }

    .banner-two img {
        position: relative;
        top: 5vw;
        left: -27vw;
        width: 30vw;
        float: right;
    }

    .testimonials>img {
        display: none;
    }

    .testimonials>div {
        padding: 0 3vw;
    }

    .testimonials .scale {
        width: 36vw;
        height: auto;
    }

    .testimonials p img {
        width: 4vw;
    }

    .testimonials>div div {
        font-size: 8vw;
    }

    .testimonials p {
        font-size: 3.7vw;
        line-height: 5vw;
    }

    .form.container {
        padding: 0 7vw;
    }

    .form.container div.title {
        font-size: 8vw;
    }

    .section-news div.title {
        font-size: 8vw;
    }

    .section-news div {
        display: block;
        width: 85%;
        margin-bottom: 13vw;
    }

    .section-news div img {
        width: 80vw !important;
    }

    .section-news>a {
        width: 80vw;
        font-size: 3.7vw;
    }

    footer {
        margin-top: 8vw;
    }

    footer .contact {
        padding-left: 10vw;
    }

    footer .contact img {
        float: left;
        width: 90vw;
        margin-left: -3vw;
    }

    footer .contact b,
    footer .contact p {
        font-size: 3.7vw;
    }

    footer .contact a {
        font-size: 3.7vw;
    }

    footer .copyright {
        font-size: 3.8vw;
        text-align: center;
    }

    footer .copyright span {
        float: none;
    }
.copyright img {
    height: 3vw;
    width: auto;
}
}